<template>
  <form>
    <div class="form-row">
      <div class="form-group col-md-auto">
        <label class="w-100">
          Fassung
          <input type="text" v-model="changed_event.fassung" class="form-control" list="movie_editions">
          <!-- Todo replace with custom vue element -->
          <!-- Not supported in Firefox for Android -->
          <datalist id="movie_editions">
            <option :key="edition.fassung" v-for="edition in movie_editions">{{edition.fassung}}</option>
          </datalist>
        </label>
      </div>
      <div class="form-group col-md-auto">
        <label class="w-100">
          Auf Website
          <select class="custom-select" v-model="changed_event.sichtbar">
            <option value="0">Nein</option>
            <option value="1">Ja</option>
          </select>
        </label>
      </div>
      <div class="form-group col-md-auto">
        <distributor-selector v-model:distributor_id="changed_event.verleiher"></distributor-selector>
      </div>
    </div>
    <h4>Vorstellungen</h4>
  <ul class="list-group ">
    <li :key="screening.abrechnungsdetails_id" v-for="(screening, index) in changed_event.screenings" class="list-group-item">
      <div class="form-row">
        <div class="col-md-auto">
          <label class="w-100">
            Hörsaal
            <select required class="custom-select" v-model="screening.hoersaal_id">
              <option :key="room.hoersaal_id" v-for="room in rooms" :value="room.hoersaal_id">{{room.hoersaalname}}</option>
            </select>
          </label>
        </div>
        <date-time-input v-model:dateTime="screening.datum_zeit"></date-time-input>
        <div class="col-md-auto d-flex" v-if="index!==0">
          <label class="w-100 mt-auto">
            <a class="btn-danger btn" @click="remove_screening(index)">Löschen</a>
          </label>
        </div>
      </div>
    </li>
    <li class="list-group-item text-center">
      <a @click="add_screening">Vorstellung hinzufügen</a>
    </li>
  </ul>
  </form>
</template>

<script>
import DateTimeInput from "@/components/DateTimeInput";
import DistributorSelector from "./DistributorSelector.vue";
import axios from "axios";
import clone from "clone";

export default {
  name: "EventEditor",
  components: {DateTimeInput, DistributorSelector},
  props: ['event','original_event'],
  emits: ['update:event'],
  data() {
    return {
      //cloning
      changed_event: clone(this.event || {}),
      rooms : [],
      movie_editions: [],
    }
  },
  methods: {
    add_screening: function () {
      this.changed_event.screenings.push({
        abrechnungsdetails_id: null,
        abrechnung_id: null,
        hoersaal_id: 1,
        datum_zeit: this.changed_event.screenings.length ?
            this.changed_event.screenings[this.changed_event.screenings.length-1].datum_zeit : "",
      })
    },
    remove_screening: function (index) {
      this.changed_event.screenings.splice(index,1)
    }
  },
  watch: {
    original_event(val) {
      this.changed_event= clone(val || {})
    },
    changed_event: {
      handler(val) {
        this.$emit("update:event", val)
      },
      deep: true
    }
  },
  created() {
    if (this.changed_event.screenings.length === 0)
      this.add_screening()
    if(this.changed_event.fassung === undefined)
      this.changed_event.fassung = ""
    if(this.changed_event.sichtbar === undefined)
      this.changed_event.sichtbar = "1"

    axios.get('/programme/locations', {
      headers: { 'Accept': 'application/json' },
      data: {},
    }).then((response) => {
      this.rooms = response.data
    }).catch(function (error) {
      console.log(error);
    })

    axios.get('/programme/editions').then((response) => {
      this.movie_editions = response.data.sort((a,b) => b.count - a.count)
    }).catch(function (error) {
      console.log(error);
    })
  }
}
</script>

<style scoped>

</style>