<template>
  <div class="col-md-auto input-date">
    <label class="w-100">
      Datum
      <input required type="date" class="form-control" v-model="date">
    </label>
  </div>
  <div class="col-md-auto input-time">
    <label class="w-100">
      Uhrzeit
      <input required type="time" class="form-control" v-model="time">
    </label>
  </div>
</template>

<script>
export default {
  name: "DateTimeInput",
  props: ['dateTime'],
  data: function () {
    const validDateTimeString = this.dateTime.match(/^\d+-\d+-\d+T\d+:\d+/)
    const defaultDateTime = new Date()
    defaultDateTime.setUTCHours(20,15)
    return {
      temporary_date: validDateTimeString ? new Date(validDateTimeString + "Z") : defaultDateTime
    }
  },
  mounted(){
    //emit changes at least once
    if(!this.dateTime)
      this.emitTimeChange()
  },
  watch: {
    dateTime(newValue) {
      let newDateTime = new Date(newValue + "Z")
      if (newDateTime instanceof Date && !isNaN(newDateTime)) {
        this.temporary_date = newDateTime
      }
    }
  },
  methods: {
    emitTimeChange() {
      let timeWithoutTimeZone = this.temporary_date.toISOString().split("Z")[0]
      this.$emit("update:dateTime", timeWithoutTimeZone)
    }
  },
  computed: {
    date: {
      get() {
        return this.temporary_date.toISOString().split('T')[0]
      },
      set(newValue) {
        const [years, month, days] = newValue.split('-')
        if (years === undefined || month === undefined || days === undefined)
          return
        this.temporary_date.setUTCFullYear(years, month - 1, days)
        this.emitTimeChange()

      }
    },
    time: {
      get() {
        let hours = this.temporary_date.getUTCHours()
        let minutes = this.temporary_date.getUTCMinutes()
        if (hours < 10) hours = "0" + hours
        if (minutes < 10) minutes = "0" + minutes
        return hours + ":" + minutes
      },
      set(newValue) {
        const [hours, minutes] = newValue.split(":")
        if (hours === undefined || minutes === undefined)
          return
        this.temporary_date.setUTCHours(hours, minutes, 0, 0)
        this.emitTimeChange()
      }
    }
  },
}
</script>

<style scoped lang="scss">
.input-date input {
  min-width: 7em;
}

.input-time input {
  min-width: 6em;
}
</style>
