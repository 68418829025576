<template>
  <div>
    <div v-if="error">{{ error }}</div>
    <div v-else-if="user === null">loading...</div>
    <template v-else>
      <table class="table table-striped">
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Wert</th>
        </tr>
        </thead>
        <tbody>
        <tr :key="key" v-for="(value, key) in user">
          <td>{{ key }}</td>
          <td>{{ formate(value) }}</td>
        </tr>
        </tbody>
      </table>
      <button @click="delete_user" class="btn btn-danger" :disabled="admin==null">Delete</button>
      <router-link class="btn btn-primary float-right" role="button" :to="`/ldap/${cn}/edit`">Edit</router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "User",
  props: ['admin', 'cn'],
  data() {
    return {
      user: null,
      error: null,
    }
  },
  created() {
    this.load_user_data()
  },
  watch: {
    'cn'() {
      this.load_user_data()
    }
  },

  methods: {
    load_user_data() {
      this.user = null
      this.error = null
      this.$axios.get(`/ldap/users/${this.cn}`).then(response => {
        this.user = response.data
      }).catch(error => {
        if (error.response.status === 404) {
          this.error = "Nutzer nicht gefunden"
        } else {
          this.error = error.response.status
        }
      })
    },
    formate(value) {
      // if number and later than year 1973 then it's a date
      if (typeof value === "number" && value>1e8)
        return (new Date(value * 1000)).toLocaleString()
      // objects with length likely array
      else if (typeof value === "object" && value.length !== undefined)
        return value.join(", ")
      else
        return value
    },
    delete_user() {
      if(!confirm(`Wollen sie den nutzer ${this.cn} wirklich lösschen`))
        return
      this.$axios.delete(`/ldap/users/${this.$route.params.cn}`, {data: this.admin}).then(() => {
        this.$router.push({path: '/ldap'})
      }).catch(error => {
        console.error(error.response)
      })
    }

  }
}
</script>

<style scoped>

</style>