<template>
  <div class="popup-container-background" v-if="visible" @click="$emit('update:visible', false)">
    <div class="popup-container" @click.stop>
      <slot></slot>
    </div>
  </div>

</template>

<script>
export default {
  name: "PopupHelper",
  props: {
    'visible': Boolean,
  },
  emits: ['update:visible']
}
</script>

<style scoped>
.popup-container-background {
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  place-items: center;
  z-index: 100;
  padding: 2em;
}
.popup-container {
  background: white;
  border: rgba(0, 0, 0, 0.2) solid 1px;
  border-radius: 5px;
  min-width: 60px;
  min-height: 60px;
  padding: 1em;
  z-index: 110;
}
</style>