<template>
<form>
  <div class="form-row">
    <div class="form-group col-md-6">
      <label class="w-100">
        Reihentitel
        <input type="text" v-model="changed_text.reihentitel" class="form-control">
      </label>
    </div>
    <div class="form-group col-md-6">
      <label class="w-100">
        Titeltausch
        <select class="custom-select" v-model="changed_text.titeltausch">
          <option value="0">Nein</option>
          <option value="1">Ja</option>
        </select>
      </label>
    </div>
  </div>
  <div class="form-group">
    <label for="plakt_text">Text</label>
    <textarea class="form-control" id="plakt_text" v-model="changed_text.text" rows="6"></textarea>
  </div>
  <div class="form-row">
    <div class="form-group col-md-2">
      <label class="w-100">
        Version
        <input type="number" readonly v-model="changed_text.version" class="form-control">
      </label>
    </div>
    <div class="form-group col-md-4">
      <label class="w-100">
        Kürzel
        <input type="text" v-model="changed_text.kuerzel" readonly class="form-control">
      </label>
    </div>
    <div class="form-group col-md-6">
      <label class="w-100">
        Änderungsbemerkung
        <input type="text" v-model="changed_text.bemerkung" class="form-control">
      </label>
    </div>
  </div>
</form>
</template>

<script>
import clone from "clone";

export default {
  name: "PlakatEditor",
  props: ["text","original_text"],
  emits: ['update:text'],
  data() {
    return {
      changed_text: {},
    }
  },
  created() {
    this.setData(this.text)


  },
  watch: {
      original_text(val) {
        this.setData(val)
      },
    changed_text: {
      handler(val) {
        this.$emit("update:text", val)
      },
      deep: true
    }
  },
  methods: {
    setData: function (val) {
      this.changed_text = clone(val || {})
      // setting defaults
      if (this.changed_text.reihentitel === undefined)
        this.changed_text.reihentitel = ""
      if (this.changed_text.titeltausch === undefined)
        this.changed_text.titeltausch = 0
      if (this.changed_text.text === undefined)
        this.changed_text.text = ""
      if (this.changed_text.version === undefined)
        this.changed_text.version = 0
      if (this.changed_text.kuerzel === undefined)
        this.changed_text.kuerzel = ""
      if (this.changed_text.bemerkung === undefined)
        this.changed_text.bemerkung = ""
    }
  }
}
</script>

<style scoped>

</style>