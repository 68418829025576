<template>
  <div class="fader-container">
    <div class="fader">

      <div class="form">
        <span @click="getFaderLevel" class="icon refresh-icon"><i class="fas fa-sync"></i></span>
        <input @keyup.enter="setFaderLevel" class="form-control" :class="current_level === level ? '' : 'text-muted'" type="number" v-model="level" min="0" max="100" step="5">
        <span class=" vertical-icons-container">
          <span @click="level++" class="icon send-icon vertical-icons"><i class="fas fa-plus"></i></span>
          <span @click="level--" class="icon send-icon vertical-icons"><i class="fas fa-minus"></i></span>
        </span>
        <span @click="setFaderLevel" class="icon send-icon"><i class="fas fa-check"></i></span>
      </div>

      <p  v-if="level == null && !errorGet" class="info-box text-info text-center">Connecting..</p>
      <p  v-if="errorGet" class="info-box text-danger text-center">{{ errorGet }}</p>
      <p  v-if="errorSet" class="info-box text-danger text-center">{{ errorSet }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FaderDial",
  data() {
    return {
      current_level: null,
      level: null,
      errorGet: null,
      errorSet: null,
    }
  },
  created() {
    this.getFaderLevel()
  },
  methods: {
    getFaderLevel() {
      axios.get("/operations/fader")
          .then(response => {
            this.level = response.data.level
            this.current_level = response.data.level
            this.errorGet = null
          })
          .catch(error => {
            this.errorGet = this.parseError(error)
          })
    },
    setFaderLevel() {
      axios.post("/operations/fader", {
        'level': this.level
      })
          .then(response => {
            this.level = response.data.level
            this.current_level = response.data.level
            this.errorSet = null
          })
          .catch(error => {
            this.errorSet = this.parseError(error)
          })
    },
    parseError(error){
      let text = error.response.statusText
      if(error.response.data && error.response.data.error)
        text += ": " + error.response.data.error
      return text
    }
  }
}
</script>

<style scoped>
.fader {

}
.fader-container {
  display: grid;
  place-items: center;
  height: 100vh
}

.form {
  font-size: 500%;
  text-align: center;
}

.form * {
  padding: 0 .2em;
}
input {
  display: inline-block;
  font-size: 100%;

}

input {
  width: 3em
}
.vertical-icons-container {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.25;
}
.vertical-icons {
  position: relative;
  display: block;
}
@media screen and (max-width: 600px) {
  .icon {
    display: block;
  }
  input {
  width: 2em
  }
}
@media screen and (max-width: 380px) {
  .vertical-icons {
    display: inline-block;
  }
}
</style>

