<template>
  <form>
    <div class="input-group">
      <select class="custom-select" :value="current_season || -1"
              @change="$emit('update:current_season', $event.target.value)">
        <option disabled value="-1">Semester Wählen</option>
        <option :key="s.semester_id" v-for="s in seasons" :value="s.semester">{{ season_to_name(s) }}</option>
      </select>
      <div class="input-group-append">
        <button @click="edit_season" class="btn"
                :class="current_season ? 'btn-outline-primary' : 'btn-outline-secondary'" type="button">Edit
        </button>
        <button @click="season_editor_visible = true" class="btn btn-outline-primary" type="button">Neues Semester
        </button>
      </div>
      <popup-helper v-model:visible="season_adder_visible">
        <season-editor @new:season="add_season" :edit_season="current_season_object"></season-editor>
      </popup-helper>
      <popup-helper v-model:visible="season_editor_visible">
        <season-editor @new:season="add_season"></season-editor>
      </popup-helper>

    </div>
  </form>
</template>

<script>
import axios from "axios";
import SeasonEditor from "./SeasonEditor.vue";
import PopupHelper from "../PopupHelper.vue";

export default {
  name: "SeasonSelector",
  components: {PopupHelper, SeasonEditor},
  emits: ['update:current_season'],
  props: ['current_season'],
  data: function () {
    return {
      seasons: [],
      season_adder_visible: false,
      current_season_object: null,
      season_editor_visible: false
    }
  },
  async created() {
    try {
      this.seasons = (await axios.get('/programme/seasons', {headers: {'Accept': 'application/json'}, data: {}})).data
      this.determine_current_semester()
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    add_season(new_season) {
      let old_season_index = this.seasons.findIndex(s => s.semester_id === new_season.semester_id)
      if (old_season_index >= 0) {
        this.seasons[old_season_index] = new_season
        this.$emit('update:current_season', new_season.semester)
      } else
        this.seasons.unshift(new_season)
    },
    edit_season() {
      this.current_season_object = this.seasons.find(a => a.semester === this.current_season)
      if (this.current_season_object)
        this.season_adder_visible = true
    },
    season_full_name(letter_or_spielzeit) {
      switch (letter_or_spielzeit) {
        case 1:
        case 'F':
          return 'Frühjahr'
        case 2:
        case 'S':
          return 'Sommer'
        case 3:
        case 'H':
          return 'Herbst'
        case 4:
        case 'W':
          return 'Winter'
        default:
            return 'Unbekannt'
      }
    },
    season_to_name(season) {
      const seasonYear = season.jahr.split('-')[0]
      //const seasonLetter = season.semester.match(/[A-Za-z]*/)[0]
      const seasonName = this.season_full_name(season.spielzeit)
      return `${seasonYear} ${seasonName} (${season.semester})`
    },
    determine_current_semester(){
      if(!this.current_season) {
        const today = (new Date()).toISOString().split("T")[0]
        let current = this.seasons.find(season => season.semester_von < today && today < season.semester_bis)
        console.info("current semester is", current.semester)
        if (current)
          this.$emit('update:current_season', current.semester)
      }
    }
  }
}
</script>

<style scoped>

</style>

