import {createApp} from "vue";
import ProgrammEditor from './components/programmEditor/ProgramEditor.vue'
import "./main.scss"
import "@fortawesome/fontawesome-free/js/all"
import "bootstrap/dist/js/bootstrap.bundle"
import { createRouter,createWebHistory} from "vue-router";
import axios from 'axios';


import Sidebar from "./components/sidebar/Sidebar";
import FaderDial from "./components/FaderDial";
import Shark from "./components/Shark/Shark";
import Ldap from "./components/ldap/Ldap.vue";
import LdapOverview from "./components/ldap/Overview.vue";
import LdapUserEditor from "./components/ldap/UserEditor.vue";
import LdapUser from "./components/ldap/User.vue";


window.axios = axios
window.Chart = require('chart.js').Chart;
window.$ = window.jQuery = require("jquery")


document.addEventListener("DOMContentLoaded", () => {
    const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]');
    if (!csrftoken)
        console.error("Failed to get CSRF token")
    else
        window.axios.defaults.headers.common['X-CSRFToken'] = csrftoken.value;
    window.axios.defaults.headers.common['Accept'] = 'application/json';
    window.axios.defaults.xsrfHeaderName = 'X-CSRFToken';
    window.axios.defaults.mode = 'same-origin';

    if (document.getElementById('vue-sidebar')) {
        createApp(Sidebar).mount('#vue-sidebar')
    }

    if (document.getElementById('vue-programme')) {
        createApp(ProgrammEditor).mount("#vue-programme")
    }
    if (document.getElementById('vue-fader-dial')) {
        createApp(FaderDial).mount("#vue-fader-dial")
    }
    if (document.getElementById('vue-shark')) {
        createApp(Shark).mount("#vue-shark")
    }

    if (document.getElementById('vue-ldap')) {
        const app = createApp(Ldap)
        const routes = [
            {path: '/ldap/', component: LdapOverview, props: true},
            {path: '/ldap/new', component: LdapUserEditor, props: true},
            {path: '/ldap/:cn', component: LdapUser, props: true},
            {path: '/ldap/:cn/edit', component: LdapUserEditor, props: true},
        ]
        const router = createRouter({
            // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
            history: createWebHistory(),
            routes, // short for `routes: routes`
        })
        app.use(router)
        app.config.globalProperties.$axios = window.axios
        app.mount("#vue-ldap")
    }
})
