<template>
<li><a :href="url" :class="{no: url==='javascript:void(0);'}"><slot></slot></a></li>
</template>

<script>
import {store} from "./store";

export default {
  name: "Entry",
  props: {
    url: {
      type: String,
      default: 'javascript:void(0);'
    }
  },
  created(){
    if(this.url === window.location.pathname){
      this.$emit("onPage")
    }
  },
  data(){
    return {
      store
    }
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
