<template>
  <template v-if="!selectedEvent">
    <season-selector class="m-2" v-model:current_season="current_season"></season-selector>
    <events v-if="current_season" v-model:selectedEvent="selectedEvent" :season="current_season"></events>
  </template>
  <EventEditor v-else v-model:event="selectedEvent"></EventEditor>

</template>

<script>
import SeasonSelector from "@/components/programmEditor/SeasonSelector";
import Events from "@/components/programmEditor/Events";
import EventEditor from "@/components/programmEditor/Editor";

export default {
  name: "Editor",
  components: {EventEditor, Events, SeasonSelector},
  data: function () {
    return {
      current_season: null,
      selectedEvent: null,
    }
  },
  created() {

  }
}
</script>

<style scoped>

</style>
