<script setup>
import {defineProps, computed} from 'vue'
import File from "./File.vue";
//const props =
let props = defineProps({
  'files': {},
  'type': String,
  'actions_enabled': {type: Boolean, default: true},
  'uploaded': {}
})
let place_holder_cells = props.actions_enabled ? [1,2,3,4] : [1,2,3]

const files_annotated = computed(() => {
  if(!props.uploaded)
    return props.files
  //structuredClone was added in Node 17 docker image based on node 14...
  //const new_files = structuredClone(props.files)
  const copy_files = JSON.parse(JSON.stringify(props.files))
  for (const file of copy_files) {
    file.target = props.uploaded.find((a) => a.file === file.file)
  }
  return copy_files
})

</script>

<template>
  <div>
    <table class="table full-width">
      <tr>
        <th colspan="10" class="text-center text-capitalize h3">{{ type }}</th>
      </tr>
      <tr>
        <th>Path</th>
        <th>Verändert</th>
        <th>Größe</th>
        <th class="" v-if="actions_enabled">Action</th>
      </tr>

      <template v-if="files===null">
        <!-- repeating placeholder for 4 rows and 4 columns -->
        <tr :key="i" v-for="i in place_holder_cells">
          <td :key="i" v-for="i in place_holder_cells">
            <div class="placeholder"></div>
          </td>
        </tr>
      </template>
      <template v-else>
        <file :key="file.file" v-for="file in files_annotated" :file="file" :type="type" :actions_enabled="actions_enabled"></file>
      </template>
    </table>
  </div>
</template>

<style scoped>
.placeholder {
  animation: pulsing infinite 1s ease-in-out;
  background: lightgrey;
  border-radius: 0.4rem;
  border: none;
  width: 100%;
  height: 1rem;
}

@keyframes pulsing {
  from, to {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>