<script setup>
import {ref, computed} from "vue"
import axios from "axios"

const files = ref([])
const statuses = ref([])

const STATUS_ERROR = -1
const STATUS_NONE = 0
const STATUS_UPLOADING = 1
const STATUS_TRANSFERRING = 2
const STATUS_SUCCESS = 3
const STATUS_WARNING = 4

function statusToText(status){
  switch (status) {
    case STATUS_ERROR: return "Fehler"
    case STATUS_NONE: return ""
    case STATUS_UPLOADING: return "wird hochladen"
    case STATUS_TRANSFERRING: return "wird übertragen"
    case STATUS_SUCCESS: return "Erfolgreich"
    case STATUS_WARNING: return "Erfolgreich mit Warnung"
  }
}

function handleFiles(event) {
  files.value = event.target.files
  statuses.value = [...Array(5)].map(() => {
    return {value: STATUS_NONE, result: {}}
  });
}

const files_with_status = computed(() => {
  const with_status = []
  for (let i = 0; i < files.value.length; i++) {
    with_status.push({file: files.value[i], status: statuses.value[i]})
  }
  return with_status
})

function upload() {
  for (let file of files_with_status.value) {
    file.status.value = STATUS_UPLOADING

    axios.postForm('/operations/keys', {
      files: [file.file]
    }, {
      onUploadProgress: progressEvent => {
        if(progressEvent.upload)
          file.status.value = STATUS_TRANSFERRING
      }
    }).then(result => {
      const result_file = result.data[0]
      file.status.result = result_file
      file.status.value = result_file.success ? STATUS_SUCCESS : STATUS_ERROR
      if (result_file.success && result_file.stdout || result_file.success && result_file.stderr) {
        file.status.value = STATUS_WARNING
      }

    }).catch(error => {
      file.status.value = STATUS_ERROR
      if (error.response && error.response.status === 403)
        alert("I'm sorry, Dave. I'm afraid I can't do that.\nDu hast leider nicht die benötigten Berechtigungen!")
      else
        console.error(error)
    })
  }
}

</script>

<template>
  <div class="text-center mb-2 mt-4">
    <h3 class="text-center text-capitalize h3 ">Keys</h3>
    <input type="file" accept=".xml" multiple @change="handleFiles">
    <button @click="upload">Upload</button>
    <div>
      <div :key="file.file.name" v-for="file in files_with_status" :class="{
        'text-danger': file.status.value === STATUS_ERROR,
        'text-info': file.status.value === STATUS_TRANSFERRING || file.status.value === STATUS_UPLOADING ,
        'text-success': file.status.value === STATUS_SUCCESS,
        'text-waring': file.status.value === STATUS_WARNING,
      }">
        <h5>{{ file.file.name }} <small class="float-right">{{statusToText(file.status.value)}}</small></h5>
        <pre>{{ file.status.result.stderr }}</pre>
        <pre class="text-info">{{ file.status.result.stdout }}</pre>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>