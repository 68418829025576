<script setup>
import {defineProps, computed, ref} from 'vue'
import axios from "axios";

const props = defineProps(['file', 'type', 'actions_enabled'])

const date = computed(() => {
  return new Date(props.file.changed * 1000)
})

const dateString = computed(() => {
  return date.value.toLocaleString()
})

function convertToHumanReadableSize(size){
  const sizes = ['KB', 'MB', 'GB', 'TB', 'PB']
  const magnitude = Math.max(0, Math.min(Math.floor(Math.log(size) / Math.log(1000)), sizes.length - 1))

  return `${parseFloat((size / Math.pow(1000, magnitude)).toFixed(1))} ${sizes[magnitude]}`
}

const size = computed(() => {
  const size = props.file.size
  return convertToHumanReadableSize(size)
})

const timeInWords = computed(() => {
  const delta = new Date() - date.value
  const sizes = ['ms', 'Sekunden', 'Minuten', 'Stunden', 'Tage', 'Monate', 'Jahre']
  const dev = [1000, 60, 60, 24, 30, 12]

  let time = delta
  let i = 0
  for (; i < dev.length; i++) {
    if (time > dev[i])
      time = time / dev[i]
    else
      break
  }

  return `vor ${time.toFixed(0)} ${sizes[i]}`
})

const sizeDelta = computed(() => {
  if(!props.file.target)
    return false
  return props.file.size - props.file.target.size
})
const sizeDeltaHuman = computed(() => {
  if(!sizeDelta.value)
    return false
  return convertToHumanReadableSize(sizeDelta.value)
})
const sizeDeltaPercent = computed(() => {
  if(!sizeDelta.value)
    return false
  return (sizeDelta.value/props.file.size*100).toFixed(0) + "%"
})

const sizeTransferdHuman = computed(() => {
  if(!transfer_progress.value)
    return false
  return convertToHumanReadableSize(transfer_progress.value.transfered_bytes / 1000)
})


const transfer_progress = ref(null)

const STATE_RUNNING = 1
const STATE_DONE = 2
const STATE_ERROR = -1
const state = ref(0)

const file_action = action => {
  const listener = (event) => {
    console.info("Preventing user to leave page before file action is done")
    event.preventDefault()
  }
  addEventListener("beforeunload", listener)

  state.value = STATE_RUNNING
  return axios.post(`/operations/shark/files/${action}`, {type: props.type, file: props.file.file}).then(response => {
    console.log(response)
    state.value = STATE_DONE
  }).catch(error => {
    state.value = STATE_ERROR
    console.log(error)
    if (error.response && error.response.status === 403)
      alert("I'm sorry, Dave. I'm afraid I can't do that.\nDu hast leider nicht die benötigten Berechtigungen!")
    if (error.response && error.response.data && error.response.data.error)
      alert(error.response.data.error)
  }).finally(() => {
    removeEventListener("beforeunload", listener)
  })
}

const poll_transfer_progress = () => {
  axios.get(`/operations/shark/files/${props.file.file}`).then(response => {
    transfer_progress.value = response.data
    if(response.data.transfered_percent != 100){
      state.value = STATE_RUNNING
      setTimeout(poll_transfer_progress, 500);
    }else{
      state.value = STATE_DONE
    }
  }).catch(() => {
    state.value = STATE_ERROR
  })
}
const transfer_files = () => {
  file_action("copy")
}
const transfer_files_async = () => {
  file_action("copy_async").then(poll_transfer_progress)
}
const delete_files = () => {
  if (!confirm("Bist du dir sicher"))
    return
  file_action("delete")
}

</script>

<template>
  <tr :class="{
    'text-danger': state === STATE_ERROR,
    'text-success': state === STATE_DONE,
    'text-info': state === STATE_RUNNING,}
">
    <td class="shark-path">{{ file.file }}</td>
    <template v-if="transfer_progress">
      <td><span v-if="transfer_progress.transfered_percent < 100">ETA </span>{{ transfer_progress.time }}</td>
      <td>{{ sizeTransferdHuman }} <span class="d-block">{{ transfer_progress.transfered_percent }}%</span></td>
      <td>{{ transfer_progress.speed }}</td>
    </template>
    <template v-else>
      <td class="shark-time">
      <span class="time-in-words">{{ timeInWords }}</span>
      <span>{{ dateString }}</span>
    </td>
    <td class="shark-size">{{ size }}</td>
    <td class="shark-action" v-if="actions_enabled">
      <div class="shark-action-icons">
        <div v-if="state===STATE_RUNNING"><i class="fas fa-spinner fa-spin"></i></div>
        <template v-else>
          <div @click="transfer_files()"
               :class="sizeDelta ? 'text-warning':''"
               :title="file.target ?`Größen unterschied zu quelle ${sizeDeltaHuman} (${sizeDeltaPercent})`:''">
            <i class="fas fa-upload" role="button"></i>
          </div>
          <div @click="transfer_files_async()">
            <i class="fas fa-shipping-fast" role="button" title="upload with status(Beta)"></i>
          </div>
          <div @click="poll_transfer_progress()">
            <i class="fas fa-stream" role="button" title="Attach to log stream(Beta)"></i>
          </div>
          <div @click="delete_files()">
            <i class="fas fa-trash" role="button" @click="delete_files"></i>
          </div>
        </template>
      </div>
    </td>
    </template>
    

  </tr>
</template>

<style scoped>
.shark-time {
  width: 11.5rem;
}

.shark-size {
  width: 6rem;
}

.shark-action {
  width: 8rem;
}

.shark-action-icons {
  display: flex;
  justify-content: space-around;
}

.shark-time > span:last-child, .shark-time:hover > span:first-child {
  display: none;
}

.shark-time:hover > span:last-child {
  display: inherit;
}
</style>