<template>
  <form>
    <div class="form-row">
      <div class="form-group col-md-auto">
        <label class="w-100">
          Semester
          <select class="custom-select" v-model="season">
            <option :value="1">Frühjahr</option>
            <option :value="2">Sommer</option>
            <option :value="3">Herbst</option>
            <option :value="4">Winter</option>
          </select>
        </label>
      </div>
      <div class="col-md-auto">
        <label class="w-100">
          Start
          <input v-model="start" @change="guess_end" type="date" class="form-control">
        </label>
      </div>
      <div class="col-md-auto">
        <label class="w-100">
          Ende
          <input v-model="end" @change="guess_preview_end" type="date" class="form-control">
        </label>
      </div>
      <div class="col-md-auto">
        <label class="w-100">
          Ende der vorschau
          <input v-model="preview_end" type="date" class="form-control">
        </label>
      </div>
    </div>
    <div>
      <span class="float-left text-success" v-if="success">{{ success }}</span>
      <span class="float-left text-danger" v-else-if="error && error.error">
        {{ error.error }}
        <div :key="intersection.id" v-for="intersection in error.intersections">
          <span class="season-name">{{ intersection.semester }}:</span> {{
            intersection.semester_von
          }} - {{ intersection.semester_bis }}
        </div>
      </span>
      <a class="btn btn-success float-right align-middle" href="#" @click="save">Save</a>
      <a class="btn btn-info float-right align-middle mx-1" href="#" @click="next" v-if="!edit_season">Next</a>
      <a class="btn btn-danger float-right align-middle mx-1" href="#" @click="delete_season" v-else>Delete</a>
    </div>
  </form>

</template>

<script>
import axios from "axios";

export default {
  name: "SeasonEditor",
  emits: ['new:season'],
  props: ['edit_season'],
  data() {
    return {
      id: null,
      season: null,
      start: null,
      end: null,
      preview_end: null,
      success: null,
      error: null,
    }
  },
  created() {
    if (this.edit_season) {
      this.id = this.edit_season.semester_id
      this.season = this.edit_season.spielzeit
      this.start = this.edit_season.semester_von
      this.end = this.edit_season.semester_bis
      this.preview_end = this.edit_season.plakat_bis
    }
  },
  methods: {
    addDays(date, number_of_days) {
      let date_object = new Date(date)
      date_object.setDate(date_object.getDate() + number_of_days)
      // "2022-11-01T00:00:00.000Z" => "2022-11-01"
      return date_object.toISOString().split('T')[0]
    },
    guess_end(force = false) {
      if (this.end === null || force === true) {
        this.end = this.addDays(this.start, 91)
        this.guess_preview_end()
      }
    },
    guess_preview_end() {
      this.preview_end = this.addDays(this.end, 14)
    },
    save() {
      let data = {
        'id': this.id,
        'season_type': this.season,
        'start': this.start,
        'end': this.end,
        'preview_end': this.preview_end
      }

      axios({
        method: this.edit_season ? 'PUT' : 'POST',
        url: '/programme/seasons',
        data: data
      }).then((response) => {
        console.log(response)
        this.$emit("new:season", response.data)
        this.success = "Saved!"
        this.error = null
      }).catch(error => {
        this.success = null
        if (error.response.data.error) {
          this.error = error.response.data
        } else
          this.error = {error: error.response.statusText}
      })
    },
    next() {
      this.start = this.addDays(this.end, 1)
      this.guess_end(true)
      // 1, 2, 3, 4,   4 => 0+1
      this.season = this.season % 4 + 1
    },
    delete_season(){
      let semester = this.edit_season.semester
      if(confirm(`Sicher das du ${semester} löschen möchtest?`))
        axios.delete('/programme/seasons/' + semester).then(() => window.location.reload())
    }
  },

}
</script>

<style scoped>
.season-name {
  width: 2.5em;
  display: inline-block;
  text-align: right;
  font-weight: bold;
}
</style>

