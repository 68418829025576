<template>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">Datum</th>
      <th scope="col">Titel</th>
      <th scope="col">Fassung</th>
      <th scope="col">Jahr</th>
    </tr>
    </thead>
    <template :key="event.abrechnung_id" v-for="event in events">
      <tr title="Zum bearbeiten an klicken" :key="screening.abrechnungsdetails_id" class="event-row"
          v-for="screening in event.screenings" @click="$emit('update:selectedEvent',event)">
        <td>{{dateTime(screening.datum_zeit)}}</td>
        <td>{{event.movie_data.filmtitel}}</td>
        <td>{{event.fassung}}</td>
        <td>{{event.movie_data.produktionsjahr}}</td>
      </tr>
    </template>

    <tr><td class="text-center" colspan="5"><a @click="new_event" type="button" class="mt-1 btn btn-outline-primary">Neue Veranstaltung anlegen</a></td></tr>

  </table>
</template>

<script>
import axios from "axios";

export default {
  name: "Events",
  props: ["season"],
  data() {
    return {
      events: []
    }
  },
  created() {
    this.loadEvents();
  },
  watch: {
    season() {
      this.loadEvents();
    }
  },
  methods:{
    loadEvents: function () {
      axios.get(`/programme/seasons/${this.season}`, {
        headers: { 'Accept': 'application/json' },
        data: {},
      }).then((response) => {
        this.events = response.data.events
      }).catch(function (error) {
        console.log(error);
      })
    },
    new_event: function (){
      let event = {
        movie_data: {},
        short_movie_data: {},
        screenings: [],
        text: {},
      }
      this.events.push(event)
      this.$emit('update:selectedEvent',event)
    },
    dateTime: function (date){
      date = new Date(date)
      const options = {
        weekday: 'short',
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        timeZone:'UTC'};
      return date.toLocaleTimeString(undefined, options)
    }
  }
}
</script>

<style scoped>
tr.event-row {
  transition: background-color .2s, color .2s;
  cursor: pointer;
}
tr.event-row:hover{
  background-color: rgba(0, 123, 255, 0.1);
  color: #007bff
}
</style>