<template>
    <label class="w-100">
      Verleiher
      <select required class="custom-select" v-model="distributor_local">
        <option :key="distributor.verleiher_id" v-for="distributor in distributors" :value="distributor.verleiher_id">
          {{ distributor.verleihname }}
        </option>
      </select>
    </label>
</template>

<script>
import axios from "axios";

export default {
  name: "DistributorSelector",
  props: ['distributor_id'],
  data() {
    return {
      distributors: []
    }
  },
  created() {
    axios.get('/programme/distributors').then((response) => {
      this.distributors = response.data
    }).catch(function (error) {
      console.log(error);
    })
  },
  computed: {
    distributor_local: {
      get() {
        return this.distributor_id
      },
      set(newValue) {
        this.$emit("update:distributor_id", newValue)
      }
    },
  },
}
</script>

<style scoped>

</style>