<template>
  <div>
    <div>
      <router-link to="/ldap/">Zur Übersicht</router-link> |
      <router-link to="/ldap/new">Neuer Nutzer</router-link>
      <div class="float-right" @click="admin_login_visible=true">
      <span
          v-if="admin">Using <i>{{admin.ldap_user }}</i>,
        credentials are cleared by refreshing or leaving the page.</span>
        <span v-else role="button">Admin modus aktivieren</span>
      </div>

    </div>
    <popup-helper v-model:visible="admin_login_visible">
      <admin-login @save_admin_login="save_admin"></admin-login>
    </popup-helper>
    <router-view :admin="admin"></router-view>
  </div>
</template>

<script>
import PopupHelper from "../PopupHelper.vue";
import AdminLogin from "./AdminLogin.vue";

export default {
  name: "Ldap",
  components: {AdminLogin, PopupHelper},
  data() {
    return {
      admin_login_visible: false,
      admin: null
    }
  },
  methods: {
    save_admin(data) {
      this.admin = data
      this.admin_login_visible = false
    }
  }
}
</script>

<style scoped>

</style>