<script setup>
import axios from "axios";
import {ref} from 'vue'
import SharkFileList from "./FileList.vue";
import KeyUpload from "./KeyUpload.vue";

//load files form backend
const files = ref({})
axios.get("/operations/shark/files").then(response => {
  files.value = response.data
})

</script>

<template>
  <div>
    <SharkFileList type="features" :files="files.features || null" :uploaded="files.uploaded_media || null"></SharkFileList>
    <SharkFileList type="trailers" :files="files.trailers || null" :uploaded="files.uploaded_media || null"></SharkFileList>
    <KeyUpload></KeyUpload>
    <SharkFileList type="uploaded keys" :files="files.uploaded_keys || null" :actions_enabled="false"></SharkFileList>
  </div>
</template>

<style scoped>

</style>