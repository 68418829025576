<template>
<div>
  <form @submit.prevent="save_user">
    <input v-model="user" type="text" placeholder="username" required>
    <input v-model="pass" type="password" placeholder="password" required>
    <button>Aktivieren</button>
  </form>
</div>
</template>

<script>
export default {
  name: "AdminLogin",
  emits: ['save_admin_login'],
  data() {
    return {
      'user': "",
      'pass': ""
    }
  },
  methods: {
    save_user(){
      this.$emit("save_admin_login", {
        'ldap_user': this.user,
        'ldap_pass': this.pass,
      })
      this.pass = ""
    }
  }
}
</script>

<style scoped>

</style>