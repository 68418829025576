<template>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">Anzeige Name</th>
      <th scope="col">Allgemeiner Name</th>
      <th scope="col">Erstellt</th>
      <th scope="col">Bearbeitet</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <template v-if="error!==null">
      <tr>
        <td colspan="10">{{error}}</td>
      </tr>
    </template>
    <template v-else-if="users===null">
      <tr>
        <td colspan="10" class="text-center">loading...</td>
      </tr>
    </template>
    <template v-else>
      <tr :key="user.dn" v-for="user in users">
        <td>{{ user.displayName }}</td>
        <td>{{ user.cn }}</td>
        <td>{{ formate_date(user.whenCreated) }}</td>
        <td>{{ formate_date(user.whenChanged) }}</td>
        <td>
          <router-link :to="`/ldap/${user.cn.replace(' ','_')}`">Show</router-link>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Overview",
  props: ['admin'],
  data() {
    return {
      users: null,
      error: null
    }
  },
  created() {
    this.$axios.get("/ldap/users").then(response => {
      this.users = response.data
    }).catch(error => {
      this.error = `Error(${error.response.status}): ${error.response.statusText}`
    })
  },
  methods: {
    formate_date(date_string) {
      let date = new Date(date_string * 1000)
      return date.toLocaleDateString()
    }
  }
}
</script>

<style scoped>

</style>