<template>
  <nav class="col no-gutters nav-side-bar" :class="{'collapsed': sidebar_collapsed}">
    <div class="logo text-center">
      <img src="/static/img/logo.svg" alt="Filmstudio Logo">
    </div>
    <ul>
      <Group :name="name" :icon="properties.icon" :entries="properties.links" :url="properties.link"
             :key="name" v-for="(properties, name) in config">
      </Group>
    </ul>
    <ul class="collapse-button">
      <Group v-if="!sidebar_collapsed" name="Leiste einklappen" icon="angle-double-left"
             @click="() => this.sidebar_collapsed = true"></Group>
      <Group v-else name="" icon="angle-double-right" @click="() => this.sidebar_collapsed = false"></Group>
    </ul>
  </nav>
</template>
<script>

import Group from "./Group";

export default {
  name: "Sidebar",
  components: {Group},
  data() {
    return {
      window_with: Number.MAX_SAFE_INTEGER,
      sidebar_collapsed: false,
      config: window.sidebar_data
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize()
  },
  unmounted() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    resize() {
      let old = this.window_with
      this.window_with = window.innerWidth
      if (this.window_with < 1250 && old >= 1250)
        this.sidebar_collapsed = true
      else if (this.window_with >= 1250 && old < 1250)
        this.sidebar_collapsed = false

    }
  }
}
</script>

<style scoped>

</style>