<template>
  <ul class="nav nav-tabs mt-1">
    <li class="nav-item">
      <a class="nav-link" href="#" @click="$emit('update:event', null)">
        <i class="fas fa-chevron-left"></i>
      </a>
    </li>
    <li class="nav-item" :key="tap" v-for="tap in taps">
      <a class="nav-link" @click="current_tab=tap" :class="{ active: tap===current_tab}">{{ tap }}</a>
    </li>
    <li class="nav-item order-2 ml-auto">
      <a v-if="save_msg===0" class="nav-link text-success" href="#" @click="save">Save</a>
      <a v-else-if="save_msg===1" class="nav-link text-success">Saved</a>
      <a v-else class="nav-link text-danger" href="#">Error</a>
    </li>
    <li class="nav-item order-2">
      <a class="nav-link text-danger" href="#" @click="reset">Reset</a>
    </li>
  </ul>

  <div class="p-3 mb-1">
      <div v-if="current_tab==='D1'">
        <p class="text-danger">Nicht für menschen gedacht, bitte weiter gehen hier gibt es nichts zusehen.</p>
        <p>Original Daten:</p>
        <pre>{{ event }}</pre>
      </div>
      <div v-if="current_tab==='D2'">
        <p class="text-danger">Nicht für menschen gedacht, bitte weiter gehen hier gibt es nichts zusehen.</p>
        <p>Bearbeitet Daten:</p>
        <pre>{{ change_event }}</pre>
      </div>

      <event-editor v-else-if="current_tab==='Veranstaltung'"
                    v-model:event="change_event" :original_event="event"></event-editor>
      <plakat-editor v-else-if="current_tab==='Plakat'"
                     v-model:text="change_event.text" :original_text="event.text"></plakat-editor>
      <movie-data-editor v-else-if="current_tab==='Filmdaten'"
                         :is_shot_movie="false"
                         v-model:movie_data="change_event.movie_data"
                         :original_movie_data="event.movie_data"></movie-data-editor>
      <movie-data-editor v-else-if="current_tab==='Kurzfilmdaten'"
                         :is_shot_movie="true"
                         v-model:movie_data="change_event.short_movie_data"
                         :original_movie_data="event.short_movie_data"></movie-data-editor>
    <transition name="fade" class="mt-1">
      <p v-if="save_msg===1" class="text-success">Gespeichert</p>
      <p v-else-if="error" class="text-danger">{{ error }}</p>
    </transition>
  </div>

</template>

<script>
import MovieDataEditor from "@/components/programmEditor/MovieDataEditor";
import PlakatEditor from "@/components/programmEditor/PlakatEditor";
import EventEditor from "@/components/programmEditor/EventEditor";
import axios from "axios";
import clone from "clone";

export default {
  name: "Editor",
  components: {EventEditor, PlakatEditor, MovieDataEditor},
  props: ['event'],
  emits: ['update:event'],
  watch: {
    event: {
      handler(val) {
        this.change_event= clone(val)
      },
      deep: true
    }
  },
  data() {
    return {
      current_tab: 'Veranstaltung',
      taps: ['D1','D2', 'Veranstaltung', 'Plakat', 'Filmdaten', 'Kurzfilmdaten'],
      change_event: clone(this.event),
      save_msg: 0,
      error: null,
    }
  },
  methods: {
    reset: function () {
      this.$emit("update:event",clone(this.event))
    },
    save: function () {
      axios.post('/programme/events', this.change_event).then((response) => {
        this.$emit("update:event",response.data)
        this.save_msg = 1
        this.error = null
      }).catch(error => {
        if(error.response.data.error)
          this.error = error.response.data.error + ": " + error.response.data.message
        else
          this.error = error.response.statusText
        this.save_msg = -1
      }).finally( () => {
        setTimeout((() => this.save_msg = 0), 2000)
      })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
