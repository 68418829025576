<template>
  <div v-if="changed_movie_data.filmdaten_id === undefined">
    <h5>Neue Filmdaten anlegen</h5>
    <div class="form-row mb-3">
      <div class="col-md-auto">
        <label class="w-100">
          Imdb id
          <input type="number" placeholder="36818" class="form-control" v-model="changed_movie_data.imdb_id">
        </label>
      </div>
      <div class="col-md-auto d-flex">
        <label class="w-100 mt-auto">
          <a class="btn-danger btn" @click="create_new_movie_data">Erstellen</a>
        </label>
      </div>
    </div>
    <h5>Filmdaten Suchen</h5>
    <div class="form-group">
      <label class="w-100">Titel oder Imbd id
        <input type="text" @keyup="search" class="form-control" placeholder="feuerzangenbowle"></label>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Titel</th>
        <th scope="col">Original Titel</th>
        <th scope="col">Jahr</th>
        <th scope="col">Imdb</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tr :key="result.filmdaten_id" v-for="result in search_results">
        <td>{{ result.filmtitel }}</td>
        <td>{{ result.originaltitel }}</td>
        <td>{{ result.produktionsjahr }}</td>
        <td><a target="_blank" :href='"https://www.imdb.com/title/tt"+result.imdb_id'
               class="text-info">{{ result.imdb_id }}</a></td>
        <td><a href='#' @click="changed_movie_data=clone(result);set_defaults()" class="text-primary">Verwenden</a></td>
      </tr>
    </table>
  </div>
  <form v-else>
    <div class="form-row">
      <div class="col-md-6">
        <label class="w-100">Imdb ID
          <input type="number" class="form-control" v-model="changed_movie_data.imdb_id"></label>
      </div>
      <div class="col-md-3">
        <distributor-selector v-model:distributor_id="changed_movie_data.verleiher"></distributor-selector>
      </div>
      <div class="offset-xl-1 col-xl-2 col-md-2 offset-md-1 d-flex">
        <label class="w-100">Filmauswahl
          <a class="btn btn-outline-danger d-block" @click="changed_movie_data.filmdaten_id = undefined">Zurücksetzen</a>
        </label>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label class="w-100">
          Filmtitel
          <input type="text" v-model="changed_movie_data.filmtitel" class="form-control">
        </label>
      </div>
      <div class="form-group col-md-6">
        <label class="w-100">
          Originaltitel
          <input type="text" v-model="changed_movie_data.originaltitel" class="form-control">
        </label>
      </div>
    </div>
    <div class="form-group">
      <label class="w-100">Regie
        <input type="text" class="form-control" v-model="changed_movie_data.regie"></label>
    </div>
    <div class="form-group">
      <label class="w-100">Darsteller
        <input type="text" class="form-control" v-model="changed_movie_data.darsteller"></label>
    </div>
    <div class="form-row">
      <div class="form-group col-md-2">
        <label class="w-100">
          Länge
          <input type="number" v-model="changed_movie_data.laenge" class="form-control">
        </label>
      </div>
      <div class="form-group col-md-3">
        <label class="w-100">
          Produktionsjahr
          <input type="number" v-model="changed_movie_data.produktionsjahr" class="form-control">
        </label>
      </div>
      <div class="form-group col-md-2">
        <label class="w-100">
          FSK
          <select required class="custom-select" v-model="changed_movie_data.fsk_id_id">
            <option :key="fsk.fsk_id" v-for="fsk in fsks" :value="fsk.fsk_id">{{ fsk.fsk }}</option>
          </select>
        </label>
      </div>
      <div class="form-group col-md-2">
        <label class="w-100">
          Land
          <input type="text" v-model="changed_movie_data.land" class="form-control">
        </label>
      </div>
      <div class="form-group col-md-3">
        <label class="w-100">
          FBW Prädikat
          <select required class="custom-select" v-model="changed_movie_data.fbw_id_id">
            <option :key="fbw.fbw_id" v-for="fbw in fbws" :value="fbw.fbw_id">{{ fbw.fbw_lang }}</option>
          </select>
        </label>
      </div>
    </div>
  </form>
</template>

<script>
import clone from "clone";
import axios from "axios";
import DistributorSelector from "./DistributorSelector.vue";

export default {
  name: "MovieDataEditor",
  components: {DistributorSelector},
  props: ['movie_data', 'original_movie_data', 'is_shot_movie'],
  emits: ['update:movie_data'],
  data() {
    return {
      changed_movie_data: clone(this.movie_data || {}),
      fbws: [],
      fsks: [],
      search_results: []
    }
  },
  created() {
    axios.get('/programme/fbw').then((response) => {
      this.fbws = response.data
    }).catch(function (error) {
      console.log(error);
    })
    axios.get('/programme/fsk').then((response) => {
      this.fsks = response.data
    }).catch(function (error) {
      console.log(error);
    })
    this.set_defaults()
  },
  methods: {
    search: function (therm) {
      axios.get('/programme/movie_data', {
        params: {
          q: therm.target.value,
          short: this.is_shot_movie ? 1 : 0
        }
      }).then((response) => {
        this.search_results = response.data
      }).catch(function (error) {
        console.log(error);
      })
    },
    default_fbw_fsk(prefix, default_value){
      // works only for fbw_id and fsk_id
      // request to the server use NAME_id
      // but server response with NAME_id_id

      //if variable is not defied
       if (!this.changed_movie_data[prefix + '_id']) {
         // if the variable which contains response from server with id in db is number, copy value
         if (typeof this.changed_movie_data[prefix] === 'number'){
           this.changed_movie_data[prefix + '_id'] = this.changed_movie_data[prefix]
         }else{
           // else use default value
           this.changed_movie_data[prefix + '_id'] = default_value
         }
       }
    },
    set_defaults: function () {
      this.default_fbw_fsk('fbw_id', 3)
      this.default_fbw_fsk('fsk_id', 6)
    },
    create_new_movie_data: function () {
      let imdb_id = this.changed_movie_data.imdb_id
      this.changed_movie_data = {
        filmdaten_id: null,
        imdb_id: imdb_id
      }
      this.set_defaults()
      //do imdb api suff
    },
    clone: function (elm) {
      return clone(elm)
    }
  },
  watch: {
    original_movie_data(val) {
      this.changed_movie_data = clone(val || {})
      this.set_defaults()
    },
    changed_movie_data: {
      handler(val) {
        this.$emit("update:movie_data", val)
      },
      deep: true
    }
  },
}
</script>

<style scoped>

</style>

