<template>
  <form @submit.prevent="save">
    <h2>{{ cn ? `Bearbeiten von ${cn}` : "Neuer Nutzer" }}</h2>
    <label>
      Username
      <input type="text" pattern="[a-z_][a-z_0-9]+" v-model="username" required :disabled="!admin">
    </label>
    <label>
      Vorname
      <input type="text" v-model="first_name" required :disabled="!admin">
    </label>
    <label>
      Nachname
      <input type="text" v-model="last_name" required :disabled="!admin">
    </label>
    <template v-if="edit_password">
      <label>
        Password
        <input type="password" minlength="8" v-model="password" :disabled="!admin">
      </label>
      <label>
        Password bestätigen
        <input type="password" minlength="8" v-model="password_confirm" :class="{'border-danger': !password_ready}" :disabled="!admin">
      </label>
    </template>
    <button :disabled="edit_password&&!password_ready||!admin">Senden</button>
    <template v-if="error">
      <p class="text-danger">{{error}}</p>
    </template>
    <template v-if="success && !cn">
      <p class="mt-4 mb-3"><span class="text-success">Erfolgreich angelegt!</span> Nicht vergessen das linux home Verzeichnis, auf ernest, muss manuell noch erstellt werden</p>
      <pre class="text-info ml-3">{{
        }}ssh user-local@ernest{{'\n'
        }}sudo cp -ar /etc/skel/ /home/{{username}}{{'\n'
        }}sudo chown {{username}}:filmstudio -R /home/{{username}}
      </pre>
    </template>
  </form>
</template>

<script>
export default {
  name: "UserEditor",
  props: {
    admin: {
      type: Object
    },
    cn: {
      type: String,
      default: null
    }
  },
  created() {
    if (this.cn)
      this.get_user()
  },
  watch: {
    cn() {
      this.edit_password = !this.cn
      this.username = ""
      this.last_name = ""
      this.first_name = ""
      this.password = null
      this.password_confirm = null
      this.get_user()
    }
  },
  computed: {
    password_ready() {
      return this.password === this.password_confirm
    },
  },
  data() {
    return {
      error: null,
      username: "",
      first_name: "",
      last_name: "",
      edit_password: !this.cn,
      password: null,
      password_confirm: null,
      success: false
    }
  },
  methods: {
    get_user() {
      if (!this.cn)
        return
      this.$axios.get(`/ldap/users/${this.cn}`).then(response => {
        let user = response.data
        this.first_name = user.givenName
        this.last_name = user.sn
        this.username = user.uid
        console.log(user)
      })
    },
    save() {
      this.success = false
      this.error = null
      let data = {
        username: this.username,
        first_name: this.first_name,
        last_name: this.last_name,
        ...this.admin
      }
      if (this.edit_password)
        data.password = this.password

      let promise
      if (this.cn === null) {
        promise = this.$axios.post('/ldap/users', data)
      } else {
        promise = this.$axios.put(`/ldap/users/${this.cn}`, data)
      }
      promise.then(response => {
        this.$emit("update:user", response.data)
      }).catch(error => {
        if (error.response.data && error.response.data.message) {
          this.error = error.response.data.message
          this.success = true
        }else
          this.error = error.response.statusText
      })
    }
  }
}
</script>

<style scoped>

</style>