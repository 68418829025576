<template>
  <li @click="openGroup()" :class="{'active':is_open}">
    <a :href="url"><i class="fas fa-fw icon" :class="`fa-${icon}`"></i><span class="group-name">{{ name }}</span></a>

    <ul v-if="entries">
      <entry @on-page="openGroup()" :key="key" :url="value" v-for="(value, key) in entries">{{key}}</entry>
    </ul>
  </li>
</template>


<script>
import {store} from "./store";
import Entry from "./Entry";

export default {
  name: "Group",
  components: {Entry},
  props: {
    name: String,
    icon: String,
    entries: {
      type: Object,
      default: () => {}
    },
    url: {
      type: String,
      default: 'javascript:void(0);'
    }
  },
  data(){
    return {
      store
    }
  },
  computed: {
    is_open(){
      return this.store.selected_group === this.name
    }
  },
  methods: {
    openGroup() {
      this.store.selected_group = this.name
    },
  },

}
</script>

<style scoped>

</style>
